@import './mixins';

%title-font {
	font-family: var(--font-playfair);
}

%default-text {
	@include defaultTextLine(large);
	text-align: justify;
}

.markdown {
	text-align: left;

	// Questions title
	h1 {
		@extend %title-font;
		font-size: 2.8rem;
		line-height: 1.43;
		margin-bottom: 3rem;
	}

	// Header text for tab
	h2 {
		@extend %title-font;
		font-size: 2.2rem;
		line-height: 1.38;
		margin-bottom: 5rem;
	}

	// Footer text for tab
	h3 {
		@extend %title-font;
		font-size: 2.8rem;
		line-height: 1.43;
		text-align: center;
		
		&:not(:last-of-type) {
			margin-top: 10rem;
		}

		&:last-of-type {
			margin-top: 4rem;
		}

		@include respond(mobile) {
			font-size: 2.6rem;
			line-height: 1.54;
		}
	}
	
	p {
      @extend %default-text;

		&:not(:last-of-type),
		&:not(:last-child) {
			margin-bottom: 5rem;
		}
	}
	
	a {
		&:link,
		&:visited {
			text-decoration: none;
			color: var(--mine-purple-dark);
		}

		&:hover {
			color: var(--mine-purple-light);
		}
	}

	strong {
		font-weight: 700;
	}

	ol, ul {
		list-style: none;
		display: grid;
		grid-template-rows: repeat(autp-fill, minmax(0, 1fr));
		row-gap: 3rem;
	}

	ul {
		padding: 0 3rem;
	}

	ol {
		padding-left: 3rem;
		
		&:not(:last-child) {
			margin-bottom: 5rem;
		}
	}

	li {
		@extend %default-text;

		// Remove margin on <p> elements within a list
		p {
			margin: 0;
		}
	}

	hr {
		height: 1px;
		background-color: var(--mine-blue-dark);
		opacity: .1;
		margin-top: 4rem;
		border: none;

		&:not(:last-of-type) {
			margin-bottom: 4rem;
		}

		&:last-of-type {
			margin-bottom: 5rem;
		}
	}

	img {
		margin-left: -1px;
		margin-top: 5px;
	}

	// Table of links for browsers cookie-policies
	blockquote {
		margin-bottom: 5rem;

		p {
			display: grid;
			grid-template-columns: 18.2rem 18.2rem;
			grid-template-rows: repeat(3, min-content);
			column-gap: 5rem;
			row-gap: 3rem;

			@include respond(mobile) {
				grid-template-columns: 1fr;
				grid-template-rows: repeat(6, 1fr);
			}
		}

		a {
			display: grid;
			grid-template-columns: min-content 1fr;
			column-gap: 1.6rem;
			align-items: center;
		}

		img {
			margin: 0;
		}
	}
}