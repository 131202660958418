.spinner {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);

   &__svg {
      animation: rotator 5s linear infinite;
      transform-origin: center;
      width: 7vw;

      @include respond(tablet) {
         width: 10vw;
      }

      @include respond(mobile) {
         width: 15vw;
      }
   }

   &__path-primary {
      stroke-dasharray: 265;
      stroke-dashoffset: 0;
      transform-origin: center;
      stroke: #7552f6;
      
      animation: dash 1.5s ease-in-out infinite;
   }
   
   &__path-secondary {
      stroke: #7552f6;
      opacity: .2;
   }
}

@keyframes rotator {
   0% { transform: rotate(0deg); }
   100% { transform: rotate(360deg); }
}

@keyframes dash {
   0% { stroke-dashoffset: 265; }
   50% {
      stroke-dashoffset: 65;
      transform:rotate(90deg);
   }
   100% {
      stroke-dashoffset: 265;
      transform:rotate(360deg);
   }
}