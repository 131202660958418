@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Playfair+Display:wght@400;700;900&family=Source+Serif+Pro:wght@400&family=Roboto:wght@300;400;700&family=Frank+Ruhl+Libre:wght@400;700&display=swap");

:root {
   --font-poppins: 'Poppins', 'sans-serif';
   --font-roboto: "Roboto", sans-serif;
   --font-lato: "Lato", sans-serif;
   --font-playfair: "Playfair Display", serif;
   --font-frank-ruhl-libre: "Frank Ruhl Libre", serif;
   --font-source-serif-pro: "Source Serif Pro", serif;

   --title-font-family: var(--font-playfair);
}

.mine-title-xl {
   font-family: var(--font-poppins);
   font-size: 42px;
   font-weight: 700;
}

.mine-title-l {
   font-family: var(--font-poppins);
   font-size: 32px;
   font-weight: 700;
}

.mine-title-m {
   font-family: var(--font-poppins);
   font-size: 30px;
   font-weight: 400;
}

.mine-title-s {
   font-family: var(--font-poppins);
   font-size: 24px;
   font-weight: 500;
}

.mine-title-xs {
   font-family: var(--font-poppins);
   font-size: 20px;
   font-weight: 500;
}

.mine-title-xxs {
   font-family: var(--font-poppins);
   font-size: 18px;
   font-weight: 400;
}