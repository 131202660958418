@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/animations/scale.css';

.tippy-content {
   position: relative;
   overflow-wrap: anywhere;
}

.tippy-close {
   position: absolute;
   width: 24px;
   height: 24px;
   top: 9px;
   right: 9px;
   fill: rgb(158, 160, 165);
   cursor: pointer;
   z-index: 1;
}

.tippy-box {
   font-size: var(--font-size-14); 
   border-radius: 10px;

   display: flex;
   justify-content: center;
   align-items: center;

   @include respond(mobile) {
      line-height: 1.42;
      width: 227px;
   }
   
   @include respond(mobile-mini) {
      width: 220px;
   }

   .tippy-content {
      padding: 9px 15px 13px;
   }
}

.tippy-box[data-theme~='light'] {
   overflow-wrap: anywhere;
   color: var(--mine-white);
   background-color: var(--mine-grey-5);
   box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
}

// .tippy-box[data-placement^=bottom]>.tippy-arrow {
//    left: 3px !important;
// }

.tippy-box[data-placement^=bottom] > .tippy-arrow::before,
.tippy-box[data-theme~='light'][data-placement^='bottom'] > .tippy-arrow::before {
   border-bottom-color: var(--mine-grey-5);
}

.tippy-arrow::before {
   box-shadow: -4px 4px 14px -4px rgba(0, 0, 0, 0.2);
}

.tippy-close {
   fill: var(--mine-white);
   display: none;
}