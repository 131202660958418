@mixin line {
   height: 1px;
   background-color: var(--mine-blue-dark);
   opacity: .1;
}

// TEXT //
@mixin defaultTextLine($line-size) {
   font-size: var(--border-radius-16);

   @if $line-size == small {
		line-height: var(--line-height-sm);
   }
   
   @if $line-size == large {
		line-height: var(--line-height-lg);
   }
}

// ROUTES //
@mixin routeSelected {
   color: var(--mine-purple-dark);
   font-weight: 700;
}

// BORDER //
@mixin transparentBorder($width) {
   border: $width solid transparent;
}

@mixin purpleBorder($width) {
   border: $width solid var(--mine-purple-dark);
}

// DIALOG //
@mixin dialogXPosition($value) {
   position: absolute;
   top: $value;
   right: $value;
}

// CIRCLE //
@mixin circle($size, $bgColor) {
   width: $size;
   height: $size;
   border-radius: 50%;
   background-color: $bgColor;
}

// Form fields borders //
@mixin form-field-focus {
   outline: none;
   transition: all .2s ease;
   border: 1px solid var(--mine-purple-dark); 
   box-shadow: 0 0 0 1px var(--mine-purple-dark);
}

@mixin form-field-error {
   outline: none;
   transition: all .2s ease;
   border: 1px solid var(--mine-red);
   box-shadow: 0 0 0 1px var(--mine-red);
}


// MEDIA QUERY //
/*
0 - 370px: Mobile-mini
370px - 600px: Mobile
600 - 1024px: tablet
1024 - 1200px: tablet-port
1200px - 1440: small-desktop
1440 - 1920px: big-desktop
1920px + : huge-desktop

$breakpoint argument choices:
- mobile-mini
- mobile
- tablet
- tablet-port
- small-desktop
- big-desktop
- huge-desktop

1em = 16px
*/
@mixin respond($breakpoint) {
   @if $breakpoint == mobile-mini {
      @media only screen and (max-width: 23.125em) { @content };
   }
   
   @if $breakpoint == mobile {
      @media only screen and (max-width: 37.5em) { @content };
   }
   
   @if $breakpoint == tablet {
      @media only screen and (max-width: 64em) { @content };
   }
   
   @if $breakpoint == tablet-port {
      @media only screen and (max-width: 75em) { @content };
   }

   @if $breakpoint == small-desktop {
      @media only screen and (max-width: 90em){ @content};
   }
   
   @if $breakpoint == big-desktop {
      @media only screen and (max-width: 120em) { @content };
   }

   @if $breakpoint == huge-desktop {
      @media only screen and (min-width: 120em) { @content };
   }

   @if $breakpoint == short-desktop {
      @media only screen and (max-height: 85em) { @content };
   }
}

//Inputs styling
@mixin input-header() {
   .input__header {
      display: inline-block;
      font-size: var(--font-size-14);
      color: var(--mine-blue-dark);
      padding-bottom: 1.1rem;
      font-weight: inherit;
      direction: ltr;
      &--required {
         color: var(--mine-red);
      }
   }
}

@mixin input() {
   %normal-weight {
      font-weight: 400;
   }
   .input {
      &__element {
         width: 100%;
         background-color: inherit;
         border: 1px solid var(--mine-grey-4);
         border-radius: 10px;
         padding: 1.3rem 1.9rem;
         font-family: var(--font-lato);
         font-size: var(--font-size-14);
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         @extend %normal-weight;
         cursor: inherit;

         &:focus {
            outline: none;
            border: 1px solid var(--mine-purple-dark);
            box-shadow: 0 0 0 1px var(--mine-purple-dark);
            transition: all 0.2s ease;
         }

         &:disabled {
            opacity: 0.5;
            pointer-events: none;
         }

         &::-webkit-input-placeholder {
            color: var(--mine-grey-4);
         }

         &.input__element--error {
            border: 1px solid var(--mine-red);
            box-shadow: 0 0 0 1px var(--mine-red);
         }
      }

      &__error {
         color: var(--mine-red);
      }

      &__hint {
         color: var(--mine-grey-normal);
      }

      &__bottom-text {
         display: flex;
         align-items: center;
         justify-content: space-between;
         line-height: 1.44;
         @extend %normal-weight;
      }
   }
}
